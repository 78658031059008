import type { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckCircle2Icon,
  LucideIcon,
} from "lucide-react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/view/components";
import { formatKpiValue, getKpiUnit } from "@/view/utils";

import { useLineStatistics } from "./line-statistics-provider";
import { Badge } from "./line-widget-badge";
import { LineWidgetDropdown } from "./line-widget-dropdown";

export function LineWidgetCycleTimeError() {
  const { i18n } = useLingui();
  return (
    <Card className="p-4 h-44 grow border rounded-xl shadow-sm">
      <CardHeader>
        <CardTitle>{i18n.t("lineOverviewWidgetCycleTimeTitle")}</CardTitle>
      </CardHeader>
      <CardContent>
        <CardDescription className="whitespace-nowrap">
          <span className="text-5xl">--:--</span>
          <span className="text-2xl"> min</span>
        </CardDescription>
      </CardContent>
    </Card>
  );
}

export function LineWidgetCycleTime() {
  const { i18n } = useLingui();
  const data = useLineStatistics();

  const formattedValue = formatKpiValue("cycle_time", data.meanCycleTime.value);
  const valueUnit = getKpiUnit("cycle_time", data.meanCycleTime.value);
  const isTargetAvailable = data.meanCycleTime.target > 0;
  const percentage = isTargetAvailable
    ? (data.meanCycleTime.value / data.meanCycleTime.target) * 100
    : 0;
  const [Icon, text, variant] = avgCycleTimeBadgeContent(percentage, i18n);

  return (
    <Card className="p-4 border rounded-xl shadow-sm h-44 min-w-min">
      <CardHeader className="relative">
        <CardTitle>{i18n.t("lineOverviewWidgetCycleTimeTitle")}</CardTitle>
        <div className="absolute top-0 right-0">
          <LineWidgetDropdown targetType="cycleTime" />
        </div>
      </CardHeader>
      <CardContent className="flex gap-10 items-end">
        <CardDescription className="font-bold whitespace-nowrap">
          <span className="text-5xl">{formattedValue}</span>
          <span className="text-2xl">{` ${valueUnit}`}</span>
        </CardDescription>
        {isTargetAvailable && data.meanCycleTime.value > 0 && (
          <Badge variant={variant}>
            <Icon className="mr-1" size={16} />
            {text}
          </Badge>
        )}
      </CardContent>
    </Card>
  );
}

function avgCycleTimeBadgeContent(
  percentage: number,
  i18n: I18n
): [icon: LucideIcon, text: string, variant: "success" | "warning" | "error"] {
  if (percentage >= 200) {
    return [
      ArrowUpIcon,
      i18n.t("lineOverviewWidgetCycleTimeBadgeSlower", {
        value: `${Math.floor(percentage / 100)}x`,
      }),
      "error",
    ];
  }
  if (percentage > 100) {
    return [
      ArrowUpIcon,
      i18n.t("lineOverviewWidgetCycleTimeBadgeAboveTarget", {
        value: `${(percentage - 100).toFixed(2)}%`,
      }),
      "error",
    ];
  }
  if (percentage == 100) {
    return [
      CheckCircle2Icon,
      i18n.t("lineOverviewWidgetCycleTimeBadgeTargetReached"),
      "success",
    ];
  }
  return [
    ArrowDownIcon,
    i18n.t("lineOverviewWidgetCycleTimeBadgeFaster", {
      value: `${(100 - percentage).toFixed(2)}%`,
    }),
    "success",
  ];
}
