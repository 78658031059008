import { useLingui } from "@lingui/react";
import { ScaleBand, ScaleLinear, scaleLinear, ScaleTime } from "d3-scale";
import { curveMonotoneX as d3curve, line as d3line } from "d3-shape";
import React, {
  PropsWithChildren,
  SVGAttributes,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";

import { cn, formatCycleTimeDifference } from "@/view/utils";
import {
  animated,
  to,
  useSpring,
  useTransition,
} from "@/view/vendor/react-spring";

import { Group } from "./chart/group";

type DataPoint<T> = {
  label: T;
  value: Record<string, number>;
};

type Dimensions = {
  width: number;
  height: number;
};

const axisLabelFontSize = 14;
const axisTickSize = 12;
const axisTextOffset = axisTickSize + 12;
const xAxisTitleOffset = 70;
const marginLeft = axisTickSize * 12; // width space for y-axis legend
const marginTop = axisTickSize * 2; // some top padding
const marginBottom = axisTickSize * 8;

function TruncatedText({
  children,
  maxWidth,
  ellipsis = "..",
  className,
  ...props
}: SVGAttributes<SVGTextElement> & {
  children: string;
  maxWidth: number;
  ellipsis?: string;
}) {
  const ref = useRef<SVGTextElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const node = ref.current;
    node.textContent = children;
    let textLength = node.getComputedTextLength();
    let textContent = node.textContent ?? "";

    while (textLength > maxWidth && textContent.length > 0) {
      textContent = textContent.slice(0, -2).trimEnd();
      node.textContent = textContent + ellipsis;
      textLength = node.getComputedTextLength();
    }
  }, [children, maxWidth, ellipsis]);

  return (
    <text
      ref={ref}
      className={cn("text-brand-gray-4", className)}
      fontSize={axisLabelFontSize}
      alignmentBaseline="text-before-edge"
      textAnchor="middle"
      stroke="white"
      strokeWidth="4"
      paintOrder="stroke"
      {...props}
    >
      {children}
    </text>
  );
}

export function AxisBottom({
  xLabelFormatter = (value) => value.toISOString(),
  xRangeCtxLabelFormatter,
  xAxisScale,
  ticks,
}: {
  xLabelFormatter?: (value: Date) => string;
  xRangeCtxLabelFormatter?: (value: Date) => string;
  xAxisScale: ScaleTime<number, number, never> | ScaleBand<Date>;
  ticks: Array<Date>;
}) {
  const axisStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 10 },
  });
  return (
    <>
      {ticks.map((date) => {
        const x = xAxisScale(date) ?? 0;

        return (
          <animated.g key={date.toJSON()} opacity={axisStyle.opacity}>
            <line
              x1={x}
              x2={x}
              y1={0}
              y2={12}
              strokeWidth={1}
              stroke="currentColor"
              className="text-brand-gray-2"
            />
            <text
              x={x}
              y={axisTextOffset}
              fontSize={axisLabelFontSize}
              alignmentBaseline="text-before-edge"
              textAnchor="middle"
              className="fill-brand-gray-4"
            >
              {xLabelFormatter(date)}
            </text>
            {xRangeCtxLabelFormatter && (
              <text
                x={x}
                y={axisTextOffset * 2}
                fontSize={axisLabelFontSize - 2}
                alignmentBaseline="text-before-edge"
                textAnchor="middle"
                className="fill-brand-gray-3"
              >
                {xRangeCtxLabelFormatter(date)}
              </text>
            )}
          </animated.g>
        );
      })}
    </>
  );
}

type TimeDataPoint = {
  y: number;
  x: number;
  label: Date;
  value: number;
};

export function DataLine({
  xAxisScale,
  yAxisScale,
  dataKey,
  data,
  chartHeight,
  showValues = true,
  dashed = false,
  futurePointsOpacity = 0.25,
  yValueFormatter = (value) => `${value}`,
}: {
  xAxisScale: ScaleTime<number, number, never> | ScaleBand<Date>;
  yAxisScale: ScaleLinear<number, number, never>;
  dataKey: string;
  data: Array<DataPoint<Date>>;
  chartHeight: number;
  showValues?: boolean;
  dashed?: boolean;
  futurePointsOpacity?: number;
  yValueFormatter?: (value: number) => string;
}) {
  const now = new Date();
  const pastPoints: Array<TimeDataPoint> = [];
  const futurePoints: Array<TimeDataPoint> = [];

  for (const item of data) {
    const label = item.label;
    const value = item.value[dataKey] ?? 0;
    const point = {
      label,
      value,
      y: yAxisScale(value) ?? 0,
      x: xAxisScale(item.label) ?? 0,
    };
    if (point.label < now) {
      pastPoints.push(point);
      // lets add last past point as the first future point
      futurePoints[0] = point;
    } else {
      futurePoints.push(point);
    }
  }

  const line = d3line<TimeDataPoint>()
    .x((d) => xAxisScale(d.label) ?? 0)
    .y((d) => yAxisScale(d.value) ?? 0)
    .curve(d3curve);

  const pastLineFrom = line(pastPoints.map((d) => ({ ...d, value: 0 }))) ?? "";
  const pastLineTo = line(pastPoints) ?? "";
  const futureLineFrom =
    line(futurePoints.map((d) => ({ ...d, value: 0 }))) ?? "";
  const futureLineTo = line(futurePoints) ?? "";

  return (
    <Group>
      {showValues && (
        <LineValues
          data={pastPoints}
          chartHeight={chartHeight}
          yValueFormatter={yValueFormatter}
        />
      )}
      <LinePath
        key={pastLineTo}
        pathFrom={pastLineFrom}
        pathTo={pastLineTo}
        opacity={1}
        dashed={dashed}
      />
      {futurePoints.length > 1 && (
        <LinePath
          key={futureLineTo}
          pathFrom={futureLineFrom}
          pathTo={futureLineTo}
          opacity={futurePointsOpacity}
          dashed={dashed}
        />
      )}
    </Group>
  );
}

function LineValues({
  data,
  chartHeight,
  yValueFormatter = (value) => `${value}`,
}: {
  chartHeight: number;
  yValueFormatter?: (value: number) => string;
  data: Array<TimeDataPoint>;
}) {
  const valuesTransition = useTransition<
    TimeDataPoint,
    { y: number; x: number; opacity: number; r: number }
  >(data, {
    keys: (d) => d.label.toJSON(),
    from: ({ x }) => ({ x, y: chartHeight, opacity: 0, r: 0 }),
    enter: ({ x, y }) => ({ x, y, opacity: 1, r: 6 }),
    leave: ({ x }) => ({ y: 0, x, opacity: 0, r: 6 }),
    update: ({ x, y }) => ({ x, y, opacity: 1, r: 6 }),
    config: {
      mass: 1,
      tension: 144,
      friction: 16,
    },
    trail: 16,
  });

  return (
    <>
      {valuesTransition((style, d) => {
        return (
          <Group key={`point-${d.label.toJSON()}`}>
            <animated.circle
              fill="currentColor"
              className="text-brand-chart-value"
              cx={style.x}
              cy={style.y}
              opacity={style.opacity}
              r={style.r}
            />
            <animated.text
              className="text-brand-black font-semibold"
              x={style.x}
              y={style.y.to((it) => it - axisTextOffset)}
              opacity={style.opacity}
              fontSize={axisLabelFontSize}
              alignmentBaseline="middle"
              textAnchor="middle"
              stroke="white"
              strokeWidth="6"
              paintOrder="stroke"
            >
              {yValueFormatter(d.value)}
            </animated.text>
          </Group>
        );
      })}
    </>
  );
}

function LinePath({
  pathFrom,
  pathTo,
  opacity,
  dashed,
}: {
  pathFrom: string;
  pathTo: string;
  opacity: number;
  dashed: boolean;
}) {
  const springProps = useSpring({
    from: { opacity: 0, path: pathFrom },
    to: { opacity, path: pathTo },
    config: {
      mass: 1,
      tension: 1500,
      friction: 100,
    },
    delay: 250,
  });

  return (
    <animated.path
      d={springProps.path}
      opacity={springProps.opacity}
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      strokeDasharray={dashed ? "4 8" : undefined}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useChartYAxis(
  dimensions: Dimensions,
  data: Array<{ value: Record<string, number> }>,
  minItemWidth: number,
  defaultMaxValue?: number
) {
  const minRequiredWidth = data.length * minItemWidth;
  const availableWidth = Math.floor(dimensions.width - marginLeft);
  const requiredWidth = Math.max(availableWidth, minRequiredWidth);
  const chartWidth = requiredWidth;
  const chartHeight = dimensions.height - marginBottom - marginTop;
  const maxValue = useMemo(() => {
    return (
      Math.max(
        ...data.map((d) => Math.max(...Object.values(d.value))),
        defaultMaxValue ?? 10
      ) * 1.2
    ); // max value +20% padding
  }, [data, defaultMaxValue]);

  const yAxisDomain = [0, maxValue];
  const yAxisScale = scaleLinear()
    .domain(yAxisDomain)
    .range([chartHeight, axisTextOffset]);

  return {
    yAxisScale,
    chartWidth,
    chartHeight,
  };
}

export function BasicChartWrapper({
  xAxisTitle,
  yAxisTitle,
  yValueFormatter = (value) => `${value}`,
  yAxisScale,
  chartHeight,
  chartWidth,
  containerHeight,
  containerWidth,
  children,
  tooltipSlot = null,
}: PropsWithChildren<{
  xAxisTitle: string;
  yAxisTitle: string;
  chartHeight: number;
  chartWidth: number;
  containerHeight: number;
  containerWidth: number;
  yValueFormatter?: (value: number) => string;
  yAxisScale: ScaleLinear<number, number, never>;
  tooltipSlot?: React.ReactNode;
}>) {
  const axisStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 10 },
  });
  const yAxisMiddle = (chartHeight + marginTop) / 2;
  const ticks = yAxisScale.ticks(4).map((value) => {
    const yOffset = yAxisScale(value) ?? 0;
    const label = yValueFormatter(value);
    return { yOffset, label, value };
  });

  return (
    <div className="relative py-6">
      <svg
        className="relative z-10 bg-gradient-to-r from-white from-30% to-80% to-white/0"
        height={containerHeight}
        width={marginLeft}
      >
        <animated.g
          style={axisStyle}
          strokeWidth={1}
          stroke="currentColor"
          className="text-brand-gray-2 absolute left-0"
        >
          <text
            className="text-brand-gray-5"
            x={axisTextOffset}
            y={yAxisMiddle + (axisTextOffset * 3) / 2}
            fontSize={axisLabelFontSize}
            alignmentBaseline="middle"
            textAnchor="middle"
            transform={`rotate(-90, ${axisTextOffset}, ${yAxisMiddle})`}
            stroke="white"
            strokeWidth="4"
            paintOrder="stroke"
          >
            {yAxisTitle}
          </text>
          {ticks.map(({ yOffset, label, value }) => (
            <Group key={`axis-left-${value}`} top={yOffset + marginTop}>
              <text
                className="text-brand-gray-4"
                x={marginLeft - axisTextOffset / 2}
                fontSize={axisLabelFontSize}
                alignmentBaseline="middle"
                textAnchor="end"
                stroke="white"
                strokeWidth="4"
                paintOrder="stroke"
              >
                {label}
              </text>
            </Group>
          ))}
        </animated.g>
      </svg>
      <div className="absolute inset-0 top-6">
        <svg className="w-full" height={containerHeight}>
          <animated.g
            style={axisStyle}
            strokeWidth={1}
            stroke="currentColor"
            className="text-brand-gray-2"
          >
            <Group left={marginLeft} top={marginTop}>
              <text
                className="text-brand-gray-5"
                x={(containerWidth - marginLeft) / 2}
                y={chartHeight + xAxisTitleOffset}
                fontSize={axisLabelFontSize}
                alignmentBaseline="text-after-edge"
                textAnchor="middle"
                stroke="white"
                strokeWidth="4"
                paintOrder="stroke"
              >
                {xAxisTitle}
              </text>
              {ticks.map(({ yOffset, value }) => (
                <Group
                  key={`grid-${value}`}
                  top={yOffset}
                  strokeWidth={1}
                  stroke="currentColor"
                  className="text-brand-gray-2"
                >
                  <line x2={chartWidth + marginLeft} />
                </Group>
              ))}
            </Group>
          </animated.g>
        </svg>
      </div>
      <div className="absolute inset-0 top-6 overflow-auto">
        <svg className="h-full min-w-full" width={chartWidth + marginLeft}>
          <Group left={marginLeft} top={marginTop}>
            {children}
          </Group>
        </svg>
        {tooltipSlot}
      </div>
    </div>
  );
}

function getMiddleValue(value: [number, number]) {
  const [start, end] = value;
  return Math.round((start + end) / 2);
}

function getBinSize([start, end]: [number, number]) {
  return Math.abs(end - start) || 1;
}

export function CycleVarianceChart({
  xAxisTitle,
  yAxisTitle,
  yValueFormatter = (value) => `${value}`,
  data,
  mean,
  dimensions,
  onBarClick,
}: {
  xAxisTitle: string;
  yAxisTitle: string;
  yValueFormatter?: (value: number) => string;
  data: Array<{
    label: [number, number];
    value: number;
  }>;
  target: number;
  mean: number;
  dimensions: Dimensions;
  onBarClick?: (value: [number, number]) => void;
}) {
  const { i18n } = useLingui();
  const { chartWidth, chartHeight, yAxisScale } = useChartYAxis(
    dimensions,
    data.map((d) => ({ ...d, value: { value: d.value } })),
    axisTickSize * 3
  );

  const binSizeInSeconds = getBinSize(data[0].label);
  const domain = data.flatMap((d) => d.label);

  const maxValue = Math.max(...domain);
  const minValue = Math.min(...domain);
  const valueDiff = maxValue - minValue;

  const numberOfBars = Math.floor(valueDiff / binSizeInSeconds);

  const xAxisScale = scaleLinear()
    .domain([minValue, maxValue])
    .rangeRound([axisTickSize * 4, chartWidth - axisTickSize * 4]);

  const [xStart, xEnd] = xAxisScale.range();

  const bandWidth = Math.floor((xEnd - xStart) / numberOfBars);
  const barWidth = bandWidth - 2;
  const meanPosX = xAxisScale(mean) ?? 0;

  const bars = data.map((d) => ({
    range: d.label,
    middle: getMiddleValue(d.label),
    value: d.value,
    x: xAxisScale(d.label[0]),
    y: yAxisScale(d.value) ?? 0,
  }));

  const axisStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 10 },
  });

  const transition = useTransition<
    (typeof bars)[number],
    { y: number; x: number; opacity: number }
  >(bars, {
    keys: (d) => d.middle,
    from: ({ x }) => ({ x, y: chartHeight, opacity: 0 }),
    enter: ({ x, y }) => ({ x, y, opacity: 1 }),
    leave: ({ x, y }) => ({ x: x - bandWidth / 2, y, opacity: 0 }),
    update: ({ x, y }) => ({ x, y, opacity: 1 }),
    config: {
      mass: 1,
      tension: 1500,
      friction: 100,
    },
    trail: 24,
  });

  return (
    <BasicChartWrapper
      xAxisTitle={xAxisTitle}
      yAxisTitle={yAxisTitle}
      yValueFormatter={yValueFormatter}
      yAxisScale={yAxisScale}
      chartHeight={chartHeight}
      chartWidth={chartWidth}
      containerWidth={dimensions.width}
      containerHeight={dimensions.height}
    >
      {/* Mean - removed due to issues with proper visualisation, this chart needs to be reworked */}
      <animated.line
        x1={meanPosX}
        x2={meanPosX}
        y1={0}
        y2={chartHeight}
        stroke="currentColor"
        strokeWidth="2"
        strokeDasharray="8 8"
        className="text-brand-blue-1"
        opacity={axisStyle.opacity}
      />
      <animated.text
        x={meanPosX}
        y={axisTickSize * 2}
        fontSize={12}
        textAnchor="middle"
        fill="currentColor"
        stroke="white"
        strokeWidth="4"
        paintOrder="stroke"
        className="text-brand-blue-1"
        opacity={axisStyle.opacity}
      >
        {i18n.t("cycleVarianceAnnotationMean")}
      </animated.text>
      {/* Mean */}
      {transition((style, d) => {
        return (
          <animated.g key={d.middle}>
            <animated.rect
              x={style.x}
              y={style.y}
              width={barWidth}
              height={style.y.to((it) => Math.max(0, chartHeight - it))}
              opacity={style.opacity}
              rx={3}
              fill="currentColor"
              className="text-brand-blue-1 hover:text-brand-blue-2 cursor-pointer"
              onClick={() => onBarClick?.(d.range)}
            />
          </animated.g>
        );
      })}
      {transition((style, d, _1, index) => {
        const [start, _] = d.range;
        const label = formatCycleTimeDifference(start);

        return (
          <animated.g key={d.middle}>
            <animated.g
              opacity={style.opacity}
              transform={to(
                [style.x, style.y],
                (x, y) =>
                  `translate(${x + barWidth / 2}, ${y - axisTextOffset})`
              )}
            >
              <TruncatedText
                maxWidth={Math.max(axisTextOffset * 3.5, bandWidth)}
                alignmentBaseline="middle"
                className="text-brand-black font-semibold pointer-events-none"
              >
                {yValueFormatter(d.value)}
              </TruncatedText>
            </animated.g>
            <Group
              strokeWidth={1}
              stroke="currentColor"
              className="text-brand-gray-2"
            >
              <animated.line
                x1={style.x}
                x2={style.x}
                y1={chartHeight}
                y2={chartHeight + axisTickSize}
                opacity={style.opacity}
              />
              <animated.g
                opacity={style.opacity}
                transform={style.x.to(
                  (x) => `translate(${x}, ${chartHeight + axisTextOffset})`
                )}
              >
                <TruncatedText maxWidth={45}>
                  {index === data.length - 1 ? `${label}+` : label}
                </TruncatedText>
              </animated.g>
            </Group>
          </animated.g>
        );
      })}
    </BasicChartWrapper>
  );
}
