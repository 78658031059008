import { PropsWithChildren } from "react";

import { cn } from "@/view/utils";

export function LineWidgetLayout({ children }: PropsWithChildren) {
  return (
    <section
      className={cn(
        "grid gap-8 mb-8",
        "lg:grid-cols-2",
        "xl:flex xl:*:flex-grow xl:*:max-w-xl xl:flex-nowrap"
      )}
    >
      {children}
    </section>
  );
}
