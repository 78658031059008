import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { useSelectedLine } from "@/view/pages/line-id/use-selected-line";
import { paths } from "@/view/routes";

import { LineMainLayout } from "../line-id/line-main-layout";
import { LineSelect } from "../line-id/line-select";
import {
  LineReportingFiltersProvider,
  ReportingFilters,
} from "./line-reporting-filters-provider";
import { LineReportingTabs } from "./line-reporting-tabs";
import { LineStatistics } from "./line-statistics";
import { LineStatisticsWidgetsErrorBoundary } from "./line-statistics-error-boundary";
import { LineStatisticsProvider } from "./line-statistics-provider";
import { LineWidgetActivity } from "./line-widget-activity";
import { LineWidgetAverageNumberOfWorkers } from "./line-widget-average-number-of-workers";
import { LineWidgetCycleTime } from "./line-widget-cycle-time";
import { LineWidgetLoading } from "./line-widget-loading";
import { LineWidgetOutput } from "./line-widget-output";
import { LineWidgetLayout } from "./line-widgets-layout";
import { TargetsOverrideFormProvider } from "./targets-override-form-provider";

const componentsMap = {
  manual: ManualLineReporting,
  mixed: ManualLineReporting,
  machine: MachineLineReporting,
} as const;

export function LineReportingPage() {
  const line = useSelectedLine();

  const Component = componentsMap[line.type];

  return (
    <LineReportingFiltersProvider>
      <LineMainLayout
        filters={
          <>
            <div className="grow">
              <LineSelect path={paths.lineReportingPath} />
            </div>
            <ReportingFilters />
          </>
        }
      >
        <Component />
      </LineMainLayout>

      <Outlet />
    </LineReportingFiltersProvider>
  );
}

function MachineLineReporting() {
  return <h1>Machine line reporting</h1>;
}

function ManualLineReporting() {
  return (
    <>
      <TargetsOverrideFormProvider>
        <LineWidgetLayout>
          <Suspense
            fallback={
              <>
                <LineWidgetLoading />
                <LineWidgetLoading />
                <LineWidgetLoading />
              </>
            }
          >
            <LineStatisticsWidgetsErrorBoundary>
              <LineStatisticsProvider>
                <LineWidgetOutput />
                <LineWidgetCycleTime />
                <LineWidgetActivity />
              </LineStatisticsProvider>
            </LineStatisticsWidgetsErrorBoundary>
          </Suspense>
          <LineWidgetAverageNumberOfWorkers />
        </LineWidgetLayout>
      </TargetsOverrideFormProvider>

      <LineReportingTabs>
        <LineStatistics />
      </LineReportingTabs>
    </>
  );
}
