import { useLingui } from "@lingui/react";
import { ScaleLinear, ScaleTime, scaleTime } from "d3-scale";
import { addHours, endOfHour, startOfHour } from "date-fns";
import { useEffect, useMemo, useState } from "react";

import { TimeGranularity } from "@/domain/statistics";
import { CoffeeBreakIcon, Group } from "@/view/components";
import {
  AxisBottom,
  BasicChartWrapper,
  DataLine,
  useChartYAxis,
} from "@/view/components/charts";
import { Label } from "@/view/components/label";
import { Switch } from "@/view/components/switch";
import { cn } from "@/view/utils";
import {
  animated,
  Interpolation,
  SpringValue,
  to,
  useSpring,
} from "@/view/vendor/react-spring";

const breakTooltipAreaHeight = 40;

const axisTickSize = 12;
const marginLeft = axisTickSize * 12; // width space for y-axis legend
const marginTop = axisTickSize * 2; // some top padding

type DataPoint<T> = {
  label: T;
  value: Record<string, number>;
};

type Dimensions = {
  width: number;
  height: number;
};

export function OutputByTimeChart({
  xAxisTitle,
  xLabelFormatter = (value) => value.toISOString(),
  xRangeCtxLabelFormatter,
  yAxisTitle,
  yValueFormatter = (value) => `${value}`,
  data,
  workingHours,
  dimensions,
  granularity,
}: {
  xAxisTitle: string;
  yAxisTitle: string;
  xLabelFormatter?: (value: Date) => string;
  xRangeCtxLabelFormatter?: (value: Date) => string;
  yValueFormatter?: (value: number) => string;
  data: Array<DataPoint<Date>>;
  workingHours: Array<[Date, Date]> | null;
  dimensions: Dimensions;
  granularity: TimeGranularity;
}) {
  const { chartWidth, chartHeight, yAxisScale } = useChartYAxis(
    dimensions,
    data,
    axisTickSize * 4
  );
  const [showTarget, setShowTarget] = useState(false);

  const ticks = useMemo(() => {
    const labels = data.map((d) => d.label);

    if (granularity !== "hour") {
      return labels;
    }

    const firstTick = startOfHour(labels[0]);
    const lastLabel = labels[labels.length - 1];

    const lastTick =
      lastLabel.getMinutes() > 0
        ? startOfHour(addHours(lastLabel, 1))
        : endOfHour(lastLabel);

    const items = [];
    let currentTick = firstTick;
    while (currentTick <= lastTick) {
      items.push(currentTick);
      currentTick = addHours(currentTick, 1);
    }

    return items;
  }, [data, granularity]);

  const ticksNumbers = ticks.map((d) => +d);
  const xAxisScale = scaleTime()
    .domain([Math.min(...ticksNumbers), Math.max(...ticksNumbers)])
    .range(
      data.length <= 4
        ? [chartWidth / 4, (chartWidth * 3) / 4]
        : [60, chartWidth - 60]
    );

  const maxY = data.reduce(
    (max, d) => Math.max(max, d.value.value, d.value.target),
    0
  );

  const breaks =
    workingHours?.reduce((acc: Array<[Date, Date]>, [, end], index) => {
      if (index < workingHours.length - 1) {
        const nextStart = workingHours[index + 1][0];
        // Ensure we capture breaks even if the end of one period is the same as the start of the next
        if (end < nextStart) {
          acc.push([end, nextStart]);
        }
      }
      return acc;
    }, []) ?? [];

  return (
    <>
      <OutputByTimeLegend
        showTarget={showTarget}
        setShowTarget={setShowTarget}
      />
      <BasicChartWrapper
        xAxisTitle={xAxisTitle}
        yAxisTitle={yAxisTitle}
        yValueFormatter={yValueFormatter}
        yAxisScale={yAxisScale}
        chartHeight={chartHeight}
        chartWidth={chartWidth}
        containerWidth={dimensions.width}
        containerHeight={dimensions.height}
        tooltipSlot={
          <>
            <div
              className="absolute"
              style={{
                left: marginLeft,
                top: marginTop,
                width: chartWidth,
                height: breakTooltipAreaHeight,
              }}
            >
              <BreakTooltip
                breaks={breaks}
                xAxisScale={xAxisScale}
                xLabelFormatter={xLabelFormatter}
              />
            </div>
            <div
              className="absolute"
              style={{
                left: marginLeft,
                top: yAxisScale(maxY) + marginTop,
                width: chartWidth,
                height: chartHeight - yAxisScale(maxY),
              }}
            >
              <OutputByTimeTooltip
                data={data}
                xAxisScale={xAxisScale}
                yAxisScale={yAxisScale}
                chartHeight={chartHeight}
                showTarget={showTarget}
                yValueFormatter={yValueFormatter}
                xLabelFormatter={xLabelFormatter}
              />
            </div>
          </>
        }
      >
        {breaks.map(([start, end], index) => {
          const startX = xAxisScale(start) ?? 0;
          const endX = xAxisScale(end) ?? 0;
          const width = endX - startX;
          const height = chartHeight;

          return (
            <g key={`break-${index}`}>
              <rect
                x={startX}
                y={0}
                width={width}
                height={height}
                fill="#2C98FF0D"
              />
              <line
                x1={startX}
                y1={0}
                x2={startX}
                y2={height}
                stroke="#18A0FB"
                strokeWidth={1}
              />
              <line
                x1={endX}
                y1={0}
                x2={endX}
                y2={height}
                stroke="#18A0FB"
                strokeWidth={1}
              />
              <g transform={`translate(${startX}, 10)`} className="relative">
                <g transform={`translate(${width / 2 - 6}, 0)`}>
                  <CoffeeBreakIcon className="pointer-events-none" />
                </g>
              </g>
            </g>
          );
        })}

        {showTarget && (
          <Group className="text-brand-neutral">
            <DataLine
              dataKey="target"
              xAxisScale={xAxisScale}
              yAxisScale={yAxisScale}
              data={data}
              chartHeight={chartHeight}
              showValues={false}
              futurePointsOpacity={1}
              dashed
            />
          </Group>
        )}
        <Group className="text-brand-blue-1">
          <DataLine
            dataKey="value"
            xAxisScale={xAxisScale}
            yAxisScale={yAxisScale}
            data={data}
            chartHeight={chartHeight}
          />
        </Group>
        <Group top={chartHeight}>
          <AxisBottom
            xAxisScale={xAxisScale}
            xLabelFormatter={xLabelFormatter}
            xRangeCtxLabelFormatter={xRangeCtxLabelFormatter}
            ticks={ticks}
          />
        </Group>
      </BasicChartWrapper>
    </>
  );
}

function OutputByTimeLegend({
  showTarget,
  setShowTarget,
}: {
  showTarget: boolean;
  setShowTarget: (checked: boolean) => void;
}) {
  const { i18n } = useLingui();
  return (
    <div className="px-12 pt-6 flex justify-between">
      <div className="flex items-center space-x-2">
        <Label htmlFor="show-target">
          {i18n.t("cycleCountTargetPrediction")}
        </Label>
        <Switch
          id="show-target"
          checked={showTarget}
          onCheckedChange={setShowTarget}
        />
      </div>
      {showTarget && (
        <ul className="flex gap-6">
          <li className="inline-flex items-center gap-2">
            <span className="rounded-full h-3 w-3 bg-brand-neutral block" />
            <span>{i18n.t("cycleCountTarget")}</span>
          </li>
          <li className="inline-flex items-center gap-2">
            <span className="rounded-full h-3 w-3 bg-brand-blue-1 block" />
            <span>{i18n.t("cycleCountActual")}</span>
          </li>
        </ul>
      )}
    </div>
  );
}

function useNow() {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 10 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);
  return now;
}

function ChartTooltip({
  children,
  position = "top",
  style,
}: {
  children: React.ReactNode;
  position?: "top" | "bottom";
  style?: {
    transform?: Interpolation<number | string, string>;
    opacity?: SpringValue<number>;
  };
}) {
  return (
    <animated.div
      className="absolute z-20 pointer-events-none top-0 left-0"
      style={style}
    >
      <div
        className={cn(
          "border-solid border-t-white border-t-8 border-b-0",
          "border-x-transparent border-x-8",
          "h-4 w-4 absolute z-10 bottom-0 left-1/2 -translate-x-1/2",
          position === "bottom" ? "-top-4 rotate-180" : "-bottom-4"
        )}
      />
      <div
        className={cn("bg-white shadow-lg p-4 rounded-lg", "whitespace-nowrap")}
      >
        {children}
      </div>
    </animated.div>
  );
}

function BreakTooltip({
  breaks,
  xAxisScale,
  xLabelFormatter,
}: {
  breaks: Array<[Date, Date]>;
  xAxisScale: ScaleTime<number, number, never>;
  xLabelFormatter: (value: Date) => string;
}) {
  const { i18n } = useLingui();
  const [selectedRange, setSelectedRange] = useState<
    [Date, Date] | undefined
  >();

  const [tooltipProps, setTooltipProps] = useSpring(() => ({
    opacity: selectedRange ? 1 : 0,
    x: 0,
  }));

  const handleMouseMove = (event: React.MouseEvent) => {
    const mouseX =
      event.clientX - event.currentTarget.getBoundingClientRect().left;
    const breakRange = breaks.find(([start, end]) => {
      const startX = xAxisScale(start) ?? 0;
      const endX = xAxisScale(end) ?? 0;
      return mouseX >= startX && mouseX <= endX;
    });

    if (breakRange) {
      setSelectedRange(breakRange);
      setTooltipProps({ opacity: 1 });

      const tooltipX =
        xAxisScale(breakRange[0]) +
        (xAxisScale(breakRange[1]) - xAxisScale(breakRange[0])) / 2;

      tooltipProps.x.set(tooltipX);
    } else {
      setSelectedRange(undefined);
      setTooltipProps({ opacity: 0 });
    }
  };

  return (
    <>
      {selectedRange && (
        <ChartTooltip
          position="bottom"
          style={{
            transform: tooltipProps.x.to(
              (x) => `translate(calc(${x}px - 50%), 40px)`
            ),
            opacity: tooltipProps.opacity,
          }}
        >
          <p className="text-brand-black text-sm text-center mb-1">
            {i18n.t("Break")}
          </p>
          <p className="text-brand-gray-4 font-thin text-sm text-center">
            {`${xLabelFormatter(selectedRange[0])}-${xLabelFormatter(selectedRange[1])}`}
          </p>
        </ChartTooltip>
      )}
      <div
        className="w-full absolute left-0 top-0 h-full"
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          setSelectedRange(undefined);
          setTooltipProps({ opacity: 0 });
        }}
      />
    </>
  );
}

function OutputByTimeTooltip({
  data,
  xAxisScale,
  yAxisScale,
  chartHeight,
  showTarget,
  yValueFormatter = (value) => `${value}`,
  xLabelFormatter,
}: {
  data: Array<DataPoint<Date>>;
  chartHeight: number;
  xAxisScale: ScaleTime<number, number, never>;
  yAxisScale: ScaleLinear<number, number>;
  showTarget: boolean;
  yValueFormatter?: (value: number) => string;
  xLabelFormatter: (value: Date) => string;
}) {
  const { i18n } = useLingui();
  const now = useNow();
  const nowPoint = useMemo(() => {
    const pointIdx = data.findIndex((d) => d.label >= now);
    return pointIdx > 0 ? data[pointIdx - 1] : undefined;
  }, [data, now]);
  const [selectedPoint, setSelectedPoint] = useState<
    DataPoint<Date> | undefined
  >();
  const nowPointPosX = nowPoint ? (xAxisScale(nowPoint.label) ?? 0) : 0;
  const nowPointPosY = nowPoint
    ? (yAxisScale(nowPoint.value["value"]) ?? 0)
    : 0;
  const [tooltipProps, setTooltipProps] = useSpring(() => ({
    opacity: nowPoint ? 1 : 0,
    x: nowPointPosX,
    y: nowPointPosY,
  }));

  const handleMouseMove = (event: React.MouseEvent) => {
    const mouseX =
      event.clientX - event.currentTarget.getBoundingClientRect().left;
    const closestPoint = data.reduce((prev, curr) => {
      const prevDistance = Math.abs(xAxisScale(prev.label) - mouseX);
      const currDistance = Math.abs(xAxisScale(curr.label) - mouseX);
      return currDistance < prevDistance ? curr : prev;
    });

    setSelectedPoint(closestPoint);
    setTooltipProps({
      x: xAxisScale(closestPoint.label) ?? 0,
      y: yAxisScale(closestPoint.value["value"]) ?? 0,
      opacity: 1,
    });
  };

  return (
    <>
      {selectedPoint && (
        <ChartTooltip
          style={{
            transform: to(
              [tooltipProps.x, tooltipProps.y],
              (x, y) => `translate(calc(${x}px - 50%), calc(${y}px - 240px))`
            ),
          }}
        >
          <p className="text-base text-center mb-1 text-brand-gray-4 font-thin">
            {xLabelFormatter(selectedPoint.label)}
          </p>
          {showTarget && (
            <p className="flex justify-between gap-6">
              <span>{i18n.t("cycleCountTarget")}:</span>
              <span className="font-bold">
                {yValueFormatter(selectedPoint.value["target"] ?? 0)}
              </span>
            </p>
          )}
          <p className="flex justify-between gap-6">
            <span>{i18n.t("cycleCountActual")}:</span>
            <span className="font-bold">
              {yValueFormatter(selectedPoint.value["value"] ?? 0)}
            </span>
          </p>
        </ChartTooltip>
      )}
      <animated.div
        className="bg-brand-blue-1 absolute z-10 pointer-events-none bottom-0"
        style={{
          width: 2,
          height: chartHeight,
          transform: tooltipProps.x.to((x) => `translate(${x}px, 0px)`),
          opacity: tooltipProps.opacity,
        }}
      />
      <div
        className="absolute left-0 top-0 right-0 h-full"
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          setSelectedPoint(nowPoint);
          setTooltipProps({
            x: nowPointPosX,
            y: nowPointPosY,
            opacity: nowPoint ? 1 : 0,
          });
        }}
      />
    </>
  );
}
