import { type I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { useState } from "react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/view/components";
import { ChartCard } from "@/view/components/chart/chart-card";
import { LineChart } from "@/view/components/chart/line-chart";
import { CycleVarianceChart } from "@/view/components/charts";
import { useSelectedDateRange } from "@/view/pages/line-id/use-selected-date-range";
import { useSelectedLine } from "@/view/pages/line-id/use-selected-line";
import { useSelectedShiftIds } from "@/view/pages/line-id/use-selected-shift-ids";
import { AreaActivity } from "@/view/pages/line-id-area-id/area-activity";
import { StationVarianceWidgets } from "@/view/pages/line-id-reporting-station-id/station-details-drawer";
import {
  StationReportingFilters,
  useStationReportingFilterActions,
} from "@/view/pages/line-id-reporting-station-id/station-reporting-filters-provider";
import { useSelectedStationDetails } from "@/view/pages/line-id-reporting-station-id/use-selected-station-details";
import { useStationStatisticsQuery } from "@/view/pages/line-id-reporting-station-id/use-station-statistics-query";
import {
  cn,
  formatDateForTimeAxis,
  formatKpiValue,
  getKpiUnit,
} from "@/view/utils";

import {
  ChartType,
  useSelectedChartType,
} from "../line-id-reporting/use-selected-line-chart";

const overviewTabsList = [
  "activity",
  "cycle_variance",
  "avg_cycle_time",
  "output_by_time",
] as const;

type OverviewTabs = (typeof overviewTabsList)[number];

function resolveLineOverviewTab(tab: ChartType): OverviewTabs {
  switch (tab) {
    case "output":
    case "output_by_time":
      return "output_by_time";
    case "avg_cycle_time":
      return "avg_cycle_time";
    case "activity":
      return "activity";
    default:
      return "cycle_variance";
  }
}

function getOverviewTabTitle(tab: OverviewTabs, i18n: I18n) {
  switch (tab) {
    case "cycle_variance":
      return i18n.t("stationOverviewTabCycleVariance");
    case "avg_cycle_time":
      return i18n.t("stationOverviewTabAvgCycleTime");
    case "output_by_time":
      return i18n.t("stationOverviewTabOutputByTime");
    case "activity":
      return i18n.t("stationOverviewTabActivity");
  }
}

export function LineBirdEyeViewStationDetailsOverview() {
  const { i18n } = useLingui();
  const defaultTab = useSelectedChartType();
  const [selectedTab, setSelectedTab] = useState<OverviewTabs>(
    resolveLineOverviewTab(defaultTab)
  );
  return (
    <Tabs
      value={selectedTab}
      onValueChange={(val) => setSelectedTab(val as OverviewTabs)}
    >
      <TabsList className="gap-0.5 border rounded-lg bg-brand-gray-1 p-0.5 mb-4 w-full">
        {overviewTabsList.map((tab) => (
          <TabsTrigger
            key={tab}
            value={tab}
            className={cn(
              "grow rounded-md px-3 py-2.5 border border-transparent",
              "data-[state=active]:bg-brand-white",
              "data-[state=active]:text-brand-black",
              "data-[state=active]:border-brand-gray-2",
              "data-[state=active]:shadow-sm"
            )}
          >
            {getOverviewTabTitle(tab, i18n)}
          </TabsTrigger>
        ))}
      </TabsList>

      <div className="flex items-center gap-4 mb-4">
        <h3 className="grow text-lg font-semibold">
          {getOverviewTabTitle(selectedTab, i18n)}
        </h3>
        <StationReportingFilters />
      </div>

      {overviewTabsList.map((tab) => {
        return (
          <TabsContent key={tab} value={tab}>
            {tab === "activity" && <AreaActivity />}
            {tab === "cycle_variance" && <CycleVariance />}
            {tab === "avg_cycle_time" && <AvgCycleTime />}
            {tab === "output_by_time" && <OutputByTime />}
          </TabsContent>
        );
      })}
    </Tabs>
  );
}

function hasData(data: Record<string, unknown>) {
  return Object.keys(data).length > 0;
}

const heightMin = 480;
const heightMax = 580;

function CycleVariance() {
  const { i18n } = useLingui();
  const actions = useStationReportingFilterActions();
  const dateRange = useSelectedDateRange();
  const line = useSelectedLine();
  const selectedStation = useSelectedStationDetails();
  const shiftIds = useSelectedShiftIds();
  const query = useStationStatisticsQuery({
    stationId: selectedStation.id,
    line,
    dateRange,
    shiftIds,
  });

  return (
    <ChartCard query={query} height={650}>
      {(data, { width }) => {
        if (hasData(data.cycleVarianceByTime)) {
          const cycleVarianceByTime = Object.values(data.cycleVarianceByTime)
            .filter((it): it is NonNullable<typeof it> => Boolean(it))
            .map((it) => ({ label: it.range, value: it.value }));
          const height = Math.max(
            heightMin,
            Math.min(heightMax, (width * 8) / 16)
          );

          return (
            <>
              <StationVarianceWidgets data={data} />
              <CycleVarianceChart
                xAxisTitle={i18n.t("chartAxisCycleTimeRange")}
                yAxisTitle={i18n.t("chartAxisVarianceCycleTime")}
                dimensions={{ width, height }}
                data={cycleVarianceByTime}
                mean={data.cycleMeanTime}
                target={data.cycleTargetTime}
                onBarClick={actions.selectDurationRange}
              />
            </>
          );
        }
      }}
    </ChartCard>
  );
}

function AvgCycleTime() {
  const { i18n } = useLingui();
  const dateRange = useSelectedDateRange();
  const line = useSelectedLine();
  const selectedStation = useSelectedStationDetails();
  const shiftIds = useSelectedShiftIds();
  const query = useStationStatisticsQuery(
    {
      stationId: selectedStation.id,
      line,
      dateRange,
      shiftIds,
    },
    {
      select: (data) => ({
        data: Object.values(data.cycleMeanTimeByTime)
          .sort((a, b) => a.date.getTime() - b.date.getTime())
          .map((it) => ({ label: it.date, value: it.value })),
        timeGranularity: data.timeGranularity,
      }),
    }
  );

  return (
    <ChartCard query={query} height={650}>
      {({ data, timeGranularity }, { width }) => {
        if (data.length > 0) {
          const height = Math.max(
            heightMin,
            Math.min(heightMax, (width * 8) / 16)
          );

          return (
            <div style={{ width, height }}>
              <LineChart
                xAxisTitle=""
                yAxisTitle={i18n.t("chartAxisMeanTimeByTime")}
                data={data}
                xLabelFormatter={formatDateForTimeAxis(timeGranularity)}
                yValueFormatter={(value) => {
                  const formattedValue = formatKpiValue("cycle_time", value);
                  const unit = getKpiUnit("cycle_time", value);
                  return `${formattedValue} ${unit}`;
                }}
              />
            </div>
          );
        }
      }}
    </ChartCard>
  );
}

function OutputByTime() {
  const { i18n } = useLingui();
  const dateRange = useSelectedDateRange();
  const line = useSelectedLine();
  const selectedStation = useSelectedStationDetails();
  const shiftIds = useSelectedShiftIds();
  const query = useStationStatisticsQuery(
    {
      stationId: selectedStation.id,
      line,
      dateRange,
      shiftIds,
    },
    {
      select: (data) => ({
        data: data.cycleCountByTime.map((it) => ({
          label: it.date,
          value: it.value,
        })),
        timeGranularity: data.timeGranularity,
      }),
    }
  );

  return (
    <ChartCard query={query} height={650}>
      {({ data, timeGranularity }, { width }) => {
        if (data.length > 0) {
          const height = Math.max(
            heightMin,
            Math.min(heightMax, (width * 8) / 16)
          );

          return (
            <div style={{ width, height }}>
              <LineChart
                xAxisTitle=""
                yAxisTitle={i18n.t("chartAxisOutputByTime")}
                data={data}
                xLabelFormatter={formatDateForTimeAxis(timeGranularity)}
              />
            </div>
          );
        }
      }}
    </ChartCard>
  );
}
