import { I18nProvider as LinguiProvider } from "@lingui/react";
import { useQuery } from "@tanstack/react-query";

import { detectLocale, i18n, lazyLoadLocale } from "@/i18n";

export function LocaleProvider({ children }: { children: React.ReactNode }) {
  const query = useQuery({
    queryKey: ["locale"],
    queryFn: () => lazyLoadLocale(detectLocale()),
  });

  if (!query.isSuccess) {
    if (query.error) {
      console.error("Failed to load locale", query.error);
    }
    return null;
  }

  return <LinguiProvider i18n={i18n}>{children}</LinguiProvider>;
}
