import { useLingui } from "@lingui/react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { format } from "date-fns";
import {
  AlertTriangleIcon,
  BookmarkCheckIcon,
  BookmarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  LoaderIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  SaveIcon,
  XIcon,
} from "lucide-react";
import {
  MediaControlBar,
  MediaController,
  MediaFullscreenButton,
  MediaPlaybackRateButton,
  MediaPlayButton,
  MediaTimeDisplay,
  MediaTimeRange,
} from "media-chrome/dist/react";
import {
  PropsWithChildren,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import {
  generatePaginationArray,
  Pagination as PaginationType,
} from "@/domain/common/pagination";
import { LineId } from "@/domain/line";
import { Tag } from "@/domain/tag";
import { Video } from "@/domain/video";
import { localeForDateFns } from "@/i18n";
import {
  Button,
  Dialog,
  DialogOverlay,
  DialogPortal,
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/view/components";
import { Autocomplete } from "@/view/components/autocomplete";
import { TagItem } from "@/view/components/tag";
import { useTags } from "@/view/pages/line-id/use-tags";
import { cn } from "@/view/utils";
import { animated, useSpring } from "@/view/vendor/react-spring";

import {
  useCreateTagForVideoMutation,
  useCreateVideoBookmarkMutation,
  useDeleteVideoBookmarkMutation,
  useTagVideoMutation,
  useUntagVideoMutation,
  useUpdateVideoDescriptionMutation,
} from "./use-videos";

function VideoPreview({ url }: { url: string }) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [loaded, setLoaded] = useState(false);
  const savedUrl = useRef(url);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const abortController = new AbortController();

    video.addEventListener("loadeddata", () => setLoaded(true), {
      signal: abortController.signal,
    });

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <video
      muted
      autoPlay={false}
      ref={videoRef}
      preload="metadata"
      className={cn(
        "cursor-pointer absolute",
        "top-1/2 -translate-y-1/2",
        "left-1/2 -translate-x-1/2",
        "transition-opacity duration-700 opacity-0",
        { "opacity-100": loaded }
      )}
      src={savedUrl.current}
    />
  );
}

export function VideoTile({
  video,
  tags,
  inComparison = false,
  onClick,
  onCompare,
}: {
  video: Video;
  tags: Array<Tag>;
  inComparison?: boolean;
  onClick?: () => void;
  onCompare?: () => void;
}) {
  return (
    <article className="mb-4">
      <div
        className={cn(
          "relative w-full aspect-video border border-brand-gray-2",
          "bg-brand-gray-1 rounded-md overflow-clip bg-cover bg-center",
          "cursor-pointer focus:outline-none focus:ring-2 focus:ring-brand-blue-1"
        )}
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClick?.();
          }
        }}
      >
        <VideoPreview url={video.url} />

        <div className="absolute inset-0 flex justify-between">
          <div className="flex flex-col pointer-events-none">
            {tags.length > 0 && (
              <ul className={cn("flex flex-col items-start gap-y-1 p-2")}>
                {tags.slice(0, 3).map((tag) => (
                  <li key={tag.id}>
                    <TagItem tag={tag} />
                  </li>
                ))}
                {tags.length > 3 && (
                  <li className="px-2" title="more tags available">
                    ...
                  </li>
                )}
              </ul>
            )}
          </div>
          <div className="flex flex-col items-end justify-between">
            <div className="m-3 px-2 py-1 bg-brand-black/60 text-brand-white text-xs rounded-md pointer-events-none">
              {formatVideoDuration(video.duration)}
            </div>
            {onCompare && (
              <button
                title={
                  inComparison ? "Remove from comparison" : "Add to comparison"
                }
                className={cn(
                  "m-3 p-2 rounded-md hover:bg-brand-black/60 transition-colors text-xs",
                  {
                    "text-brand-chart-improving": !inComparison,
                    "text-brand-chart-warning-2": inComparison,
                  }
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  onCompare();
                }}
              >
                {inComparison ? <MinusCircleIcon /> : <PlusCircleIcon />}
              </button>
            )}
          </div>
        </div>
      </div>
      <VideoTitle video={video} />
    </article>
  );
}

export function VideoTitle({ video }: { video: Video }) {
  const { i18n } = useLingui();
  return (
    <h4 className="text-brand-black font-bold text-sm mt-2 truncate">
      {i18n.t("videoSnippetTitle", { id: video.id })}
    </h4>
  );
}

export function VideoList({
  minItemWidth,
  isLoading = false,
  className,
  children,
}: PropsWithChildren<{
  className?: string;
  minItemWidth?: number;
  isLoading?: boolean;
}>) {
  return (
    <div className="grow">
      <ul
        className={cn(
          "grid gap-x-6",
          {
            "blur-sm": isLoading,
            "grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 min-[2400px]:grid-cols-6":
              !minItemWidth,
          },
          className
        )}
        style={
          minItemWidth
            ? {
                gridTemplateColumns: `repeat(auto-fill, minmax(${minItemWidth}px, 1fr))`,
              }
            : {}
        }
      >
        {children}
      </ul>
    </div>
  );
}

export function VideoListItem({
  children,
  className,
  ...props
}: React.ComponentProps<"li">) {
  return (
    <li className={cn(className)} {...props}>
      {children}
    </li>
  );
}

export function VideoListPagination({
  isLoading,
  totalVideosCount,
  pagination,
  onChangePage,
}: {
  isLoading: boolean;
  totalVideosCount: number;
  pagination: PaginationType;
  onChangePage: (page: number) => void;
}) {
  const totalPages = Math.ceil(totalVideosCount / pagination.limit);
  const page = Math.floor(pagination.offset / pagination.limit) + 1;
  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            onClick={
              isLoading || page == 1 ? undefined : () => onChangePage(page - 1)
            }
          />
        </PaginationItem>
        {generatePaginationArray(totalPages, page).map((it) => (
          <PaginationItem key={it}>
            <PaginationLink
              isActive={it == page}
              onClick={isLoading ? undefined : () => onChangePage(it)}
            >
              {it}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationNext
            onClick={
              isLoading || page == totalPages
                ? undefined
                : () => onChangePage(page + 1)
            }
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
}

export function VideoComparisonToggle({
  videosCount,
  onClick,
}: {
  videosCount: number;
  onClick: () => void;
}) {
  const { i18n } = useLingui();
  return (
    <Button
      analyticsEvent="video_compare_button_clicked"
      className={cn(
        "text-brand-white text-base",
        "bg-brand-blue-1 hover:bg-brand-blue-2",
        "w-auto h-auto px-4 py-3 rounded-md"
      )}
      onClick={onClick}
    >
      {i18n.t("showVideoComparisonButton", {
        count: videosCount,
      })}
    </Button>
  );
}

export function VideoComparisonDrawer({
  open,
  onClose,
  children,
}: PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>) {
  const { i18n } = useLingui();
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (open === false) {
          onClose();
        }
      }}
    >
      <DialogPortal className="items-end sm:items-end">
        <DialogOverlay className="z-20" />
        <DialogPrimitive.Content
          onClick={(e) => e.stopPropagation()}
          className={cn(
            "w-full max-h-[920px] h-full",
            "flex flex-col z-30 relative",
            "bg-brand-white shadow-lg rounded-md transition ease-in-out",
            "data-[state=open]:animate-in data-[state=open]:duration-500 data-[state=open]:slide-in-from-bottom",
            "data-[state=closed]:animate-out data-[state=closed]:duration-500 data-[state=closed]:slide-out-to-bottom"
          )}
        >
          <div className="h-full flex flex-col ">
            <header className="flex gap-4 items-center p-4 border-b">
              <h2 className="grow text-xl font-bold text-brand-black">
                {i18n.t("videoComparisonDrawerTitle")}
              </h2>
              <Button
                analyticsEvent="analyze_video_close_button_clicked"
                className={cn(
                  "bg-brand-white focus:outline-none",
                  "text-brand-gray-4 hover:bg-brand-gray-1",
                  "w-auto h-auto p-2 rounded-md",
                  "flex items-center justify-center"
                )}
                onClick={onClose}
              >
                <XIcon />
              </Button>
            </header>
            <section className="px-6 py-12 grow overflow-auto">
              {children}
            </section>
          </div>
        </DialogPrimitive.Content>
      </DialogPortal>
    </Dialog>
  );
}

export function VideoDialog({
  open,
  title,
  onPrev,
  onNext,
  onClose,
  children,
}: PropsWithChildren<{
  open: boolean;
  title: string;
  onPrev?: () => void;
  onNext?: () => void;
  onClose: () => void;
}>) {
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (open === false) {
          onClose();
        }
      }}
    >
      <DialogPortal>
        <DialogOverlay className="z-20" />
        <DialogPrimitive.Content
          onClick={(e) => e.stopPropagation()}
          className={cn(
            "mx-auto max-w-3xl max-h-[95vh] min-h-[560px] w-full",
            "flex flex-col z-30 relative",
            "bg-brand-white shadow-lg rounded-md transition ease-in-out",
            "data-[state=open]:animate-in data-[state=open]:duration-500 data-[state=open]:fade-in",
            "data-[state=closed]:animate-out data-[state=closed]:duration-500 data-[state=closed]:fade-out"
          )}
        >
          <header className="flex gap-4 items-center p-4">
            <h2 className="grow text-xl font-bold text-brand-black">{title}</h2>
            <div className="flex gap-1">
              <Button
                analyticsEvent="analyze_video_prevnext_button_clicked"
                className={cn(
                  "bg-brand-white focus:outline-none border",
                  "text-brand-gray-4 hover:bg-brand-gray-1",
                  "w-auto h-auto p-2 rounded-md",
                  "flex items-center justify-center"
                )}
                disabled={!onPrev}
                onClick={onPrev}
              >
                <ChevronLeftIcon />
              </Button>
              <Button
                analyticsEvent="analyze_video_prevnext_button_clicked"
                className={cn(
                  "bg-brand-white focus:outline-none border",
                  "text-brand-gray-4 hover:bg-brand-gray-1",
                  "w-auto h-auto p-2 rounded-md",
                  "flex items-center justify-center"
                )}
                disabled={!onNext}
                onClick={onNext}
              >
                <ChevronRightIcon />
              </Button>
            </div>
            <Button
              analyticsEvent="analyze_video_close_button_clicked"
              className={cn(
                "bg-brand-white focus:outline-none",
                "text-brand-gray-4 hover:bg-brand-gray-1",
                "w-auto h-auto p-2 rounded-md",
                "flex items-center justify-center"
              )}
              onClick={onClose}
            >
              <XIcon />
            </Button>
          </header>
          {children}
        </DialogPrimitive.Content>
      </DialogPortal>
    </Dialog>
  );
}

export function VideoPlayer({ video }: { video: Video }) {
  const opacity = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 650,
  });
  return (
    <animated.div style={opacity} className="relative h-full">
      <MediaController className="absolute inset-0">
        <video
          className="w-full h-full"
          slot="media"
          src={video.url}
          preload="auto"
          muted
          loop
        />
        <MediaControlBar className="bg-brand-black/10">
          <MediaPlayButton />
          <MediaTimeRange />
          <MediaTimeDisplay showDuration />
          <MediaPlaybackRateButton />
          <MediaFullscreenButton />
        </MediaControlBar>
      </MediaController>
    </animated.div>
  );
}

export function VideoTagsForm({
  lineId,
  video,
  videoTags,
}: {
  lineId: LineId;
  video: Video;
  videoTags: Tag[];
}) {
  const { i18n } = useLingui();
  const tagsStore = useTags();
  const allTags = useMemo(() => {
    return Object.values(tagsStore.tagsById ?? {});
  }, [tagsStore.tagsById]);

  const createTagMutation = useCreateTagForVideoMutation({ lineId });
  const tagVideoMutation = useTagVideoMutation();
  const untagVideoMutation = useUntagVideoMutation();

  const areTagsUpdating =
    createTagMutation.isPending ||
    tagVideoMutation.isPending ||
    untagVideoMutation.isPending;

  return (
    <Autocomplete
      createNewItemLabel={i18n.t("videoLibraryCreateNewTagLabel")}
      emptyValueLabel={i18n.t("videoLibraryAssignTagsLabel")}
      options={allTags}
      value={videoTags}
      isLoading={areTagsUpdating}
      onChange={({ reason, value }) => {
        if (reason === "select") {
          tagVideoMutation.mutate({
            tagId: value.id,
            videoId: video.id,
          });
        } else if (reason === "deselect") {
          untagVideoMutation.mutate({
            tagId: value.id,
            videoId: video.id,
          });
        } else if (reason === "create") {
          createTagMutation.mutate({
            name: value,
            videoId: video.id,
          });
        }
      }}
    />
  );
}

type DescriptionReducerState = {
  initValue: string;
  value: string;
  isDirty: boolean;
  isValid: boolean;
};

type DescriptionReducerAction =
  | {
      type: "update";
      value: string;
    }
  | {
      type: "init";
    };

const MAX_DESCRIPTION_LENGTH = 120;

function isVideoDescriptionValid(description: string | null | undefined) {
  return (description ?? "").length <= MAX_DESCRIPTION_LENGTH;
}

function descriptionReducer(
  state: DescriptionReducerState,
  action: DescriptionReducerAction
) {
  if (action.type === "update") {
    const value = action.value;
    return {
      ...state,
      value,
      isDirty: state.initValue !== value,
      isValid: isVideoDescriptionValid(value),
    };
  }
  if (action.type === "init") {
    return { ...state, initValue: state.value, isDirty: false };
  }
  return state;
}

export function VideoDescriptionForm({ video }: { video: Video }) {
  const { i18n } = useLingui();
  const [state, dispatch] = useReducer(descriptionReducer, {
    initValue: video.description ?? "",
    value: video.description ?? "",
    isValid: isVideoDescriptionValid(video.description),
    isDirty: false,
  });
  const updateDescriptionMutation =
    useUpdateVideoDescriptionMutation(reinitDescription);
  const description = state.value;
  const isFormValid = state.isValid;
  const isFormDirty = state.isDirty;
  const isVideoUpdating = updateDescriptionMutation.isPending;

  function setDescription(value: string) {
    dispatch({ type: "update", value });
  }

  function reinitDescription() {
    dispatch({ type: "init" });
  }

  return (
    <div className="flex flex-col gap-2">
      <form
        className="flex items-start gap-2"
        onSubmit={(e) => {
          e.preventDefault();
          updateDescriptionMutation.mutate({ videoId: video.id, description });
        }}
      >
        <textarea
          className={cn(
            "w-full px-2 py-2.5 border border-brand-gray-2 rounded-md",
            {
              "border-brand-warning": isFormDirty && !isFormValid,
            }
          )}
          rows={4}
          placeholder={i18n.t("videoDescriptionPlaceholder")}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <Button
          analyticsEvent="video_description_updated"
          className={cn(
            "group bg-brand-white hover:bg-brand-gray-2 focus:outline-none",
            "text-brand-black whitespace-nowrap",
            "disabled:text-brand-gray-3",
            "w-auto h-auto p-2 rounded-md",
            "flex items-center justify-center"
          )}
          type="submit"
          disabled={isVideoUpdating || !isFormDirty || !isFormValid}
        >
          {isVideoUpdating ? (
            <LoaderIcon className="animate-spin" />
          ) : (
            <SaveIcon
              className={cn("text-brand-blue-1", {
                "text-brand-gray-3": !isFormDirty || !isFormValid,
              })}
            />
          )}
          {i18n.t("videoLibraryDrawerSaveVideoButton")}
        </Button>
      </form>
      {!isFormValid && (
        <div className="text-brand-warning text-xs px-2 py-1 bg-red-100 rounded-sm">
          {i18n.t("videoDescriptionMaxLengthError", {
            maxLength: MAX_DESCRIPTION_LENGTH,
          })}
        </div>
      )}
      {updateDescriptionMutation.isError && (
        <div className="text-brand-warning text-xs px-2 py-0.5 bg-red-100 rounded-sm">
          {i18n.t("errorUnknownMessage")}
        </div>
      )}
    </div>
  );
}

export function BookmarkVideoButton({
  video,
  onVideoUpdate,
}: {
  video: Video;
  onVideoUpdate?: (video: Video) => void;
}) {
  const { i18n } = useLingui();
  const createBookmarkMutation = useCreateVideoBookmarkMutation((value) =>
    onVideoUpdate?.({ ...video, ...value })
  );
  const deleteBookmarkMutation = useDeleteVideoBookmarkMutation((value) =>
    onVideoUpdate?.({ ...video, ...value })
  );
  const isBookmarkUpdating =
    createBookmarkMutation.isPending || deleteBookmarkMutation.isPending;
  const hasUpdateFailed =
    createBookmarkMutation.isError || deleteBookmarkMutation.isError;
  return (
    <Button
      className={cn(
        "bg-brand-white focus:outline-none",
        "text-brand-black hover:bg-brand-gray-1",
        "w-auto h-auto p-2 rounded-md",
        "flex items-center justify-center",
        {
          "text-brand-warning": hasUpdateFailed && !isBookmarkUpdating,
        }
      )}
      analyticsEvent={
        video.bookmarked ? "video_bookmark_removed" : "video_bookmark_added"
      }
      disabled={isBookmarkUpdating}
      onClick={() => {
        if (video.bookmarked) {
          deleteBookmarkMutation.mutate({ videoId: video.id });
        } else {
          createBookmarkMutation.mutate({ videoId: video.id });
        }
      }}
    >
      {hasUpdateFailed ? (
        <AlertTriangleIcon />
      ) : isBookmarkUpdating ? (
        <LoaderIcon className="animate-spin" />
      ) : video.bookmarked ? (
        <BookmarkCheckIcon className="text-brand-blue-1" />
      ) : (
        <BookmarkIcon className="text-brand-blue-1" />
      )}
      {video.bookmarked
        ? i18n.t("videoLibraryDeleteBookmarkButton")
        : i18n.t("videoLibraryCreateBookmarkButton")}
    </Button>
  );
}

function formatVideoDuration(duration: number) {
  const locale = localeForDateFns();
  const date = new Date(0, 0, 0, 0, 0, Math.round(duration));

  if (duration < 600) {
    return format(date, "m:ss", { locale });
  } else if (duration < 3600) {
    return format(date, "mm:ss", { locale });
  } else {
    return format(date, "H:mm:ss", { locale });
  }
}
