import { z } from "zod";

import {
  absAreaCoordinates,
  areaColorSchema,
  areaIdSchema,
  cameraIdSchema,
} from "./areas-of-interests";
import { lineIdSchema } from "./line";
import { stationIdSchema } from "./station";
import { activityByProductSchema } from "./statistics";

const stationCyclesAndEventsFiltersSchema = z.object({
  timestampStart: z.date(),
  timestampEnd: z.date(),
  stationId: stationIdSchema,
});

const camerasByLineIdFiltersSchema = z.object({
  lineId: lineIdSchema,
});

export const cameraSchema = z.object({
  id: cameraIdSchema,
  camType: z.union([z.literal(0), z.literal(1)]),
  kinesisVideoStream: z.object({
    name: z.string(),
    arn: z.string(),
  }),
  resWidth: z.number().nonnegative(),
  resHeight: z.number().nonnegative(),
  areas: z.array(
    z.object({
      id: areaIdSchema,
      coordinates: absAreaCoordinates,
      group: z.object({
        id: z.string(),
        color: areaColorSchema,
        name: z.string(),
      }),
    })
  ),
});

export const eventTypeSchema = z.union([
  z.literal("product_start"),
  z.literal("product_end"),
  z.literal("person_start"),
  z.literal("person_end"),
]);

export const cycleTypeSchema = z.union([
  z.literal("product"),
  z.literal("person"),
]);

export const timeWindowTypeSchema = z.union([
  z.literal("product"),
  z.literal("person"),
  z.literal("person_product"),
  z.literal("empty"),
]);

const eventSchema = z.object({
  id: z.string(),
  type: eventTypeSchema,
  timestamp: z.date(),
  metadata: z.record(z.unknown()),
  areaId: z.string(),
  stationId: z.string(),
  cameraId: z.string(),
});

const cycleSchema = z.object({
  cycleId: z.string(),
  variantId: z.string(),
  type: cycleTypeSchema,
  timestampStart: z.date(),
  timestampEnd: z.date(),
});

const timeWindowSchema = z.object({
  variantId: z.string(),
  type: timeWindowTypeSchema,
  startTime: z.date(),
  endTime: z.date(),
});

export const stationCyclesAndEventsSchema = z.object({
  cycles: z.array(cycleSchema),
  events: z.array(eventSchema),
  activities: z.array(activityByProductSchema),
  timeWindows: z.array(timeWindowSchema),
});

export type StationCyclesAndEventsFilters = z.infer<
  typeof stationCyclesAndEventsFiltersSchema
>;
export type StationCyclesAndEvents = z.infer<
  typeof stationCyclesAndEventsSchema
>;
export type CamerasByLineIdFilters = z.infer<
  typeof camerasByLineIdFiltersSchema
>;
export type Camera = z.infer<typeof cameraSchema>;
