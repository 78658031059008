import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

type DataPoint<T> = {
  label: T;
  value: number;
};

const CHART_MARGIN = {
  top: 48,
  right: 36,
  left: 52,
  bottom: 64,
};

const X_AXIS = {
  labelOffset: 64,
  tickSize: 10,
  tickLabelSize: 12,
  padding: {
    left: 56,
    right: 30,
  },
};

const Y_AXIS = {
  tickSize: 10,
  tickLabelSize: 12,
  padding: {
    bottom: 64,
  },
  labelSize: 14,
  labelOffset: -56, // negative to move the label to the left
};

export function LineChart<LabelDataType = Date>({
  data,
  xAxisTitle,
  yAxisTitle,
  xRangeCtxLabelFormatter,
  xLabelFormatter = (value) => `${value}`,
  yValueFormatter = (value) => `${value}`,
}: {
  xAxisTitle: string;
  yAxisTitle: string;
  xLabelFormatter?: (value: LabelDataType) => string;
  yValueFormatter?: (value: number) => string;
  data: Array<DataPoint<LabelDataType>>;
  xRangeCtxLabelFormatter?: (value: LabelDataType) => string;
}) {
  return (
    <ResponsiveContainer className="text-brand-blue-1">
      <RechartsLineChart data={data} margin={CHART_MARGIN}>
        <CartesianGrid
          className="stroke-brand-gray-3/75"
          vertical={false}
          strokeWidth={0.5}
        />
        <XAxis
          className="text-brand-gray-3"
          dataKey="label"
          tickLine={true}
          tickFormatter={xLabelFormatter}
          tickSize={X_AXIS.tickSize}
          label={{
            value: xAxisTitle,
            position: "bottom",
            offset: X_AXIS.labelOffset,
          }}
          padding={X_AXIS.padding}
          tick={(props) => (
            <XAxisTickLabel
              {...props}
              xLabelFormatter={xLabelFormatter}
              xRangeCtxLabelFormatter={xRangeCtxLabelFormatter}
            />
          )}
          stroke="currentColor"
          axisLine={{
            stroke: "currentColor",
            strokeWidth: 1,
          }}
        />
        <YAxis
          tickFormatter={yValueFormatter}
          axisLine={false}
          tickLine={false}
          tick={(props) => {
            const { x, y, payload } = props;
            return (
              <text
                x={x}
                y={y + 4}
                textAnchor="end"
                fontSize={Y_AXIS.tickLabelSize}
              >
                {yValueFormatter(payload.value)}
              </text>
            );
          }}
          label={{
            value: yAxisTitle,
            angle: -90,
            position: "center",
            fill: "black",
            dx: Y_AXIS.labelOffset,
            fontSize: Y_AXIS.labelSize,
          }}
        />
        <Line
          type="monotone"
          dataKey="value"
          stroke="currentColor"
          strokeWidth={3}
          dot={{
            fill: "currentColor",
            r: 4,
          }}
          animationDuration={750}
        >
          <LabelList
            position="top"
            offset={20}
            formatter={(value: number) => yValueFormatter?.(value) ?? value}
            fill="black"
            fontSize={14}
            fontWeight="bold"
          />
        </Line>
      </RechartsLineChart>
    </ResponsiveContainer>
  );
}

function XAxisTickLabel({
  x,
  y,
  payload,
  xLabelFormatter,
  xRangeCtxLabelFormatter,
}: {
  x: number;
  y: number;
  payload: { value: Date };
  xLabelFormatter: (value: Date) => string;
  xRangeCtxLabelFormatter?: (value: Date) => string;
}) {
  return (
    <g transform={`translate(${x},${y + 10})`}>
      <text
        className="text-brand-gray-4"
        x={0}
        y={0}
        textAnchor="middle"
        fill="currentColor"
        fontSize={X_AXIS.tickLabelSize}
      >
        {xLabelFormatter(payload.value)}
      </text>
      {xRangeCtxLabelFormatter && (
        <text
          className="text-brand-gray-3"
          x={0}
          y={15}
          textAnchor="middle"
          fill="currentColor"
          fontSize={X_AXIS.tickLabelSize}
        >
          {xRangeCtxLabelFormatter(payload.value)}
        </text>
      )}
    </g>
  );
}
