import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  RowsIcon,
  XIcon,
} from "lucide-react";
import { useMemo, useState } from "react";

import { StationId } from "@/domain/station";
import { StationStatistics } from "@/domain/statistics";
import {
  Button,
  Card,
  CardContent,
  CardTitle,
  Dialog,
  DialogOverlay,
  DialogPortal,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/view/components";
import { LineChart } from "@/view/components/chart/line-chart";
import { CycleVarianceChart } from "@/view/components/charts";
import { LoadingStatus } from "@/view/components/loading-status";
import { ChartsErrorStatus } from "@/view/pages/line-id-reporting/line-statistics";
import { useElementSize } from "@/view/providers/use-element-size";
import {
  cn,
  formatDateForTimeAxis,
  formatKpiValue,
  formatRangeForTimeAxis,
  getKpiUnit,
} from "@/view/utils";

import { useSelectedDateRange } from "../line-id/use-selected-date-range";
import { useSelectedLine } from "../line-id/use-selected-line";
import { useSelectedShiftIds } from "../line-id/use-selected-shift-ids";
import {
  ChartType,
  useSelectedChartType,
} from "../line-id-reporting/use-selected-line-chart";
import {
  StationReportingFilters,
  useStationReportingFilterActions,
} from "./station-reporting-filters-provider";
import { StationVideos } from "./station-videos";
import { useSelectedStationDetails } from "./use-selected-station-details";
import {
  DrawerTab,
  drawerTabs,
  useSelectedDrawerTab,
} from "./use-selected-tab";
import { useStationStatisticsQuery } from "./use-station-statistics-query";

const overviewTabsList = [
  "cycle_variance",
  "avg_cycle_time",
  "output_by_time",
] as const;

type OverviewTabs = (typeof overviewTabsList)[number];

/**
 * @todo Use the OverviewDrawer component
 * @see {@link https://www.notion.so/deltia/Use-OverviewDrawer-component-for-s-StationDetailsDrawer-adb43db0aea241fab0feb7d56d6e6740?pvs=4 | Use `OverviewDrawer` component for s `StationDetailsDrawer`}
 *
 * @todo Create router paths for tabs
 * @see {@link https://www.notion.so/deltia/Create-video-and-overview-routes-for-station-overview-drawer-tabs-890cde400773468ba77e8e5f82d2a8dd?pvs=4 | Create video and overview routes for station overview drawer tabs}
 */
export function StationDetailsDrawer({
  onStationSelect,
}: {
  onStationSelect: (stationId: StationId | null) => void;
}) {
  const { i18n } = useLingui();
  const selectedLine = useSelectedLine();
  const selectedStation = useSelectedStationDetails();
  const drawerTab = useSelectedDrawerTab();
  const actions = useStationReportingFilterActions();

  const allIds = selectedLine.stations.map((s) => s.id);
  const stationName = selectedStation?.name || "---";
  const lineName = selectedLine?.name || "---";

  const currentIndex = selectedStation.id
    ? allIds.indexOf(selectedStation.id)
    : -1;
  const previousIndex = currentIndex - 1;
  const nextIndex = currentIndex + 1;

  function hasPreviousStation() {
    return currentIndex - 1 >= 0;
  }

  function hasNextStation() {
    return currentIndex + 1 < allIds.length;
  }

  function selectPreviousStation() {
    if (!hasPreviousStation()) return;
    onStationSelect(allIds[previousIndex]);
  }

  function selectNextStation() {
    if (!hasNextStation()) return;
    onStationSelect(allIds[nextIndex]);
  }

  function closeDrawer() {
    onStationSelect(null);
  }

  return (
    <>
      <Dialog
        open
        onOpenChange={(open) => {
          if (open) return;
          closeDrawer();
        }}
      >
        <DialogPortal>
          <DialogOverlay className="z-20 opacity-20" />
          <DialogPrimitive.Content
            onClick={(e) => e.stopPropagation()}
            className={cn(
              "fixed z-30 right-0 top-0 bottom-0 overflow-hidden",
              "flex flex-col min-w-[480px] max-w-6xl w-full",
              "bg-brand-white shadow-lg transition ease-in-out",
              "data-[state=open]:animate-in data-[state=open]:duration-300 data-[state=open]:slide-in-from-right",
              "data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=closed]:slide-out-to-right"
            )}
          >
            <Tabs
              className="h-full flex flex-col"
              value={drawerTab}
              onValueChange={(val) => {
                actions.selectDrawerTab(val as DrawerTab);
              }}
            >
              <header className={cn("px-6 py-4 border-b")}>
                <div className="flex justify-between items-center gap-4 mb-4">
                  <div
                    className={cn(
                      "bg-brand-neutral-shade text-brand-blue-1",
                      "w-auto h-auto p-2 rounded-full border",
                      "flex items-center justify-center"
                    )}
                  >
                    <RowsIcon className="w-6 h-6" />
                  </div>
                  <div className="grow">
                    <h3 className="font-semibold text-brand-black text-lg">
                      {stationName}
                    </h3>
                    <h4 className="text-brand-gray-4 text-sm">{lineName}</h4>
                  </div>
                  <div className="flex gap-1">
                    <Button
                      analyticsEvent="station_details_prev_button_clicked"
                      disabled={!hasPreviousStation()}
                      className={cn(
                        "bg-brand-white focus:outline-none border",
                        "text-brand-gray-4 hover:bg-brand-gray-1",
                        "w-auto h-auto p-2 rounded-md",
                        "flex items-center justify-center"
                      )}
                      onClick={() => selectPreviousStation()}
                    >
                      <ChevronLeftIcon className="w-6 h-6" />
                    </Button>
                    <Button
                      analyticsEvent="station_details_next_button_clicked"
                      disabled={!hasNextStation()}
                      className={cn(
                        "bg-brand-white focus:outline-none border",
                        "text-brand-gray-4 hover:bg-brand-gray-1",
                        "w-auto h-auto p-2 rounded-md",
                        "flex items-center justify-center"
                      )}
                      onClick={() => selectNextStation()}
                    >
                      <ChevronRightIcon className="w-6 h-6" />
                    </Button>
                  </div>
                  <Button
                    analyticsEvent="station_details_close_button_clicked"
                    className={cn(
                      "bg-brand-white focus:outline-none",
                      "text-brand-gray-4 hover:bg-brand-gray-1",
                      "w-auto h-auto p-2 rounded-md",
                      "flex items-center justify-center"
                    )}
                    onClick={() => closeDrawer()}
                  >
                    <XIcon className="w-6 h-6" />
                  </Button>
                </div>
                <TabsList className="justify-start gap-0.5 border rounded-lg bg-brand-gray-1 p-0.5">
                  {drawerTabs.map((tab) => (
                    <TabsTrigger
                      key={tab}
                      value={tab}
                      className={cn(
                        "rounded-md px-3 py-2.5 border border-transparent",
                        "data-[state=active]:bg-brand-white",
                        "data-[state=active]:text-brand-black",
                        "data-[state=active]:border-brand-gray-2",
                        "data-[state=active]:shadow-sm"
                      )}
                    >
                      {getMainTabTitle(tab, i18n)}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </header>
              <section className="grow overflow-y-scroll">
                {drawerTabs.map((tab) => (
                  <TabsContent
                    key={tab}
                    value={tab}
                    className="px-6 py-8 min-h-full"
                  >
                    {tab === "overview" && <OverviewTab />}
                    {tab === "videos" && <StationVideos />}
                  </TabsContent>
                ))}
              </section>
            </Tabs>
          </DialogPrimitive.Content>
        </DialogPortal>
      </Dialog>
    </>
  );
}

function resolveLineOverviewTab(tab: ChartType): OverviewTabs {
  switch (tab) {
    case "output":
    case "output_by_time":
      return "output_by_time";
    case "avg_cycle_time":
      return "avg_cycle_time";
    default:
      return "cycle_variance";
  }
}

function OverviewTab() {
  const { i18n } = useLingui();
  const dafaultTab = useSelectedChartType();
  const [selectedTab, setSelectedTab] = useState<OverviewTabs>(
    resolveLineOverviewTab(dafaultTab)
  );
  const selectedStation = useSelectedStationDetails();
  const line = useSelectedLine();
  const dateRange = useSelectedDateRange();
  const shiftIds = useSelectedShiftIds();
  const query = useStationStatisticsQuery({
    stationId: selectedStation.id,
    line,
    dateRange,
    shiftIds,
  });

  return (
    <>
      <div className="flex items-center gap-4 mb-4">
        <h3 className="grow text-lg font-semibold">
          {getOverviewTabTitle(selectedTab, i18n)}
        </h3>
        <StationReportingFilters />
      </div>
      <Card className="p-4 min-h-[640px] border relative">
        <Tabs
          value={selectedTab}
          onValueChange={(val) => setSelectedTab(val as OverviewTabs)}
        >
          <TabsList className="gap-0.5 border rounded-lg bg-brand-gray-1 p-0.5 mb-4">
            {overviewTabsList.map((tab) => (
              <TabsTrigger
                key={tab}
                value={tab}
                className={cn(
                  "grow rounded-md px-3 py-2.5 border border-transparent",
                  "data-[state=active]:bg-brand-white",
                  "data-[state=active]:text-brand-black",
                  "data-[state=active]:border-brand-gray-2",
                  "data-[state=active]:shadow-sm"
                )}
              >
                {getOverviewTabTitle(tab, i18n)}
              </TabsTrigger>
            ))}
          </TabsList>
          {(query.isPending || query.isFetching) && <LoadingStatus />}
          {query.isError && <ChartsErrorStatus />}
          {query.isSuccess && (
            <StationOverviewCharts
              isLoading={query.isFetching}
              data={query.data}
            />
          )}
        </Tabs>
      </Card>
    </>
  );
}

/**
 *
 * @todo Replace with charts components from LineBirdEyeViewStationDetailsOverview
 * @see {@link https://www.notion.so/deltia/Reuse-overview-charts-component-from-LineBirdEyeViewStationDetailsOverview-ad40eefa05c74f7bb3113419f0f302a6?pvs=4 | Reuse overview charts component from LineBirdEyeViewStationDetailsOverview}
 */
function StationOverviewCharts({
  isLoading,
  data,
}: {
  isLoading: boolean;
  data: StationStatistics;
}) {
  const { i18n } = useLingui();
  const actions = useStationReportingFilterActions();
  const dateRange = useSelectedDateRange();
  const { ref, width } = useElementSize();
  const heightMin = 480;
  const heightMax = 580;
  const height = Math.max(heightMin, Math.min(heightMax, (width * 8) / 16));

  function hasData(data: Record<string, unknown>) {
    return Object.keys(data).length > 0;
  }

  const cycleVarianceByTime = useMemo(() => {
    return Object.values(data.cycleVarianceByTime).reduce(
      (aggr, curr) => {
        if (curr) {
          aggr.push({ label: curr.range, value: curr.value });
        }
        return aggr;
      },
      [] as Array<{
        label: [number, number];
        value: number;
      }>
    );
  }, [data]);

  const cycleMeanTimeByTime = useMemo(() => {
    return Object.values(data.cycleMeanTimeByTime)
      .sort((a, b) => a.date.getTime() - b.date.getTime())
      .map((it) => ({ label: it.date, value: it.value }));
  }, [data]);

  const cycleCountByTime = useMemo(() => {
    return data.cycleCountByTime.reduce(
      (aggr, curr) => {
        aggr.push({ label: curr.date, value: curr.value });
        return aggr;
      },
      [] as Array<{
        label: Date;
        value: number;
      }>
    );
  }, [data.cycleCountByTime]);

  const xLabelFormatter = formatDateForTimeAxis(data.timeGranularity);
  const xRangeCtxLabelFormatter = formatRangeForTimeAxis(
    data.timeGranularity,
    dateRange.values
  );

  return (
    <CardContent
      ref={(el) => {
        ref.current = el;
      }}
      className={cn("grow h-full p-0", {
        "blur-sm": isLoading,
      })}
    >
      {overviewTabsList.map((tab) => (
        <TabsContent key={tab} value={tab}>
          {tab === "cycle_variance" && (
            <>
              <StationVarianceWidgets data={data} />
              {hasData(data.cycleVarianceByTime) ? (
                <CycleVarianceChart
                  xAxisTitle={i18n.t("chartAxisCycleTimeRange")}
                  yAxisTitle={i18n.t("chartAxisVarianceCycleTime")}
                  dimensions={{ width, height }}
                  data={cycleVarianceByTime}
                  mean={data.cycleMeanTime}
                  target={data.cycleTargetTime}
                  onBarClick={(range) => {
                    actions.selectDurationRange(range, DrawerTab.Videos);
                  }}
                />
              ) : (
                <ChartsErrorStatus />
              )}
            </>
          )}
          {tab === "avg_cycle_time" && (
            <>
              {hasData(data.cycleMeanTimeByTime) ? (
                <div style={{ width, height }}>
                  <LineChart
                    xAxisTitle=""
                    yAxisTitle={i18n.t("chartAxisMeanTimeByTime")}
                    data={cycleMeanTimeByTime}
                    xLabelFormatter={xLabelFormatter}
                    yValueFormatter={(value) => {
                      const formattedValue = formatKpiValue(
                        "cycle_time",
                        value
                      );
                      const unit = getKpiUnit("cycle_time", value);
                      return `${formattedValue}${unit}`;
                    }}
                    xRangeCtxLabelFormatter={xRangeCtxLabelFormatter}
                  />
                </div>
              ) : (
                <ChartsErrorStatus />
              )}
            </>
          )}
          {tab === "output_by_time" && (
            <>
              {data.cycleCountByTime.length > 0 ? (
                <div style={{ width, height }}>
                  <LineChart
                    xAxisTitle=""
                    yAxisTitle={i18n.t("chartAxisOutputByTime")}
                    data={cycleCountByTime}
                    xLabelFormatter={xLabelFormatter}
                    xRangeCtxLabelFormatter={xRangeCtxLabelFormatter}
                  />
                </div>
              ) : (
                <ChartsErrorStatus />
              )}
            </>
          )}
        </TabsContent>
      ))}
    </CardContent>
  );
}

export function StationVarianceWidgets({ data }: { data: StationStatistics }) {
  const { i18n } = useLingui();
  const cycleTimeMeanValue = formatKpiValue("cycle_time", data.cycleMeanTime);
  const cycleTimeMeanUnit = getKpiUnit("cycle_time", data.cycleMeanTime);
  const cycleTimeMedianValue = formatKpiValue(
    "cycle_time",
    data.cycleMedianTime
  );
  const cycleTimeMedianUnit = getKpiUnit("cycle_time", data.cycleMedianTime);
  const cycleTimeTargetValue = formatKpiValue(
    "cycle_time",
    data.cycleTargetTime
  );
  const cycleTimeTargetUnit = getKpiUnit("cycle_time", data.cycleTargetTime);
  const cycleCountValue = formatKpiValue("cycle_count", data.cycleCount);
  const cycleTimeVarianceValue = formatKpiValue(
    "cycle_time",
    data.cycleTimeVariance
  );
  const cycleTimeVarianceUnit = getKpiUnit(
    "cycle_time",
    data.cycleTimeVariance
  );

  return (
    <section className="flex items-stretch flex-wrap gap-4 mb-8">
      <div className="flex-grow max-w-xl min-w-min">
        <Card className="p-4 border rounded-xl shadow-sm">
          <CardTitle className="text-sm font-normal text-brand-gray-5">
            {i18n.t("cycleVarianceMeanValue")}
          </CardTitle>
          <div className="flex items-baseline gap-1">
            <span className="text-4xl font-semibold">{cycleTimeMeanValue}</span>
            <span className="text-lg font-semibold">{cycleTimeMeanUnit}</span>
          </div>
        </Card>
      </div>
      <div className="flex-grow max-w-xl min-w-min">
        <Card className="p-4 border rounded-xl shadow-sm">
          <CardTitle className="text-sm font-normal text-brand-gray-5">
            {i18n.t("cycleVarianceMedianValue")}
          </CardTitle>
          <div className="flex items-baseline gap-1">
            <span className="text-4xl font-semibold">
              {cycleTimeMedianValue}
            </span>
            <span className="text-lg font-semibold">{cycleTimeMedianUnit}</span>
          </div>
        </Card>
      </div>
      {data.cycleTargetTime > 0 && (
        <div className="flex-grow max-w-xl min-w-min">
          <Card className="p-4 border rounded-xl shadow-sm">
            <CardTitle className="text-sm font-normal text-brand-gray-5">
              {i18n.t("cycleVarianceMedianTarget")}
            </CardTitle>
            <div className="flex items-baseline gap-1">
              <span className="text-4xl font-semibold">
                {cycleTimeTargetValue}
              </span>
              <span className="text-lg font-semibold">
                {cycleTimeTargetUnit}
              </span>
            </div>
          </Card>
        </div>
      )}
      <div className="flex-grow max-w-xl min-w-min">
        <Card className="p-4 border rounded-xl shadow-sm">
          <CardTitle className="text-sm font-normal text-brand-gray-5">
            {i18n.t("Cycle time variance")}
          </CardTitle>
          <div className="flex items-baseline gap-1">
            <span className="text-4xl font-semibold">
              {cycleTimeVarianceValue}
            </span>
            <span className="text-lg font-semibold">
              {cycleTimeVarianceUnit}
            </span>
          </div>
        </Card>
      </div>
      <div className="flex-grow max-w-xl min-w-min">
        <Card className="p-4 border rounded-xl shadow-sm">
          <CardTitle className="text-sm font-normal text-brand-gray-5">
            {i18n.t("cycleCountValue")}
          </CardTitle>
          <span className="text-4xl font-semibold">{cycleCountValue}</span>
        </Card>
      </div>
    </section>
  );
}

function getMainTabTitle(tab: DrawerTab, i18n: I18n) {
  switch (tab) {
    case "overview":
      return i18n.t("stationDetailsTabOverview");
    case "videos":
      return i18n.t("stationDetailsTabVideos");
  }
}

function getOverviewTabTitle(tab: OverviewTabs, i18n: I18n) {
  switch (tab) {
    case "cycle_variance":
      return i18n.t("stationOverviewTabCycleVariance");
    case "avg_cycle_time":
      return i18n.t("stationOverviewTabAvgCycleTime");
    case "output_by_time":
      return i18n.t("stationOverviewTabOutputByTime");
  }
}
